@tailwind base;
@tailwind components;
@tailwind utilities;

/* CHECKBOX TOGGLE SWITCH */
/* @apply rules for documentation, these do not work as inline style */
.toggle-checkbox:checked {
  @apply: right-0 border-red-500;
  right: 0;
  border-color: red;
}
.toggle-checkbox:checked + .toggle-label {
  @apply: bg-red-500;
  background-color: red;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco,
    Consolas, "Courier New", monospace;
}
